@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
@import url("../../App/Vars.css");

.SwapBox-collateral-tooltip-text {
  font-weight: normal;
}

.btn-onhover:hover {
  border: 1px solid #cafc4f !important;
  background-color: #121117 !important;
}

#slippageP {
  text-align: right;
  width: 100%;
  padding: 0px 5px;
  /* background: linear-gradient(180deg, rgba(19, 22, 20, 0.916667) 0%, rgba(19, 22, 20, 0) 99.99%); */
  color: var(--blanco);
}

.readonly {
  background-color: transparent !important;
}

.functional {
  background-color: var(--fondo-1-negro) !important;
}

#boton-swap-test-usdt {
  display: inline-flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  background: var(--fondo-2-gris);
  border: none;
  outline: none;
  width: 100%;
  margin-bottom: 5px;
  margin-top: -5px;
  color: var(--verde);
  font-size: 14px;
}

.especial-buttons .Tab-option {
  background-color: var(--fondo-2-gris);
  padding: 10px 0px;
  margin-top: -10px;
}

.especial-buttons * {
  width: 100% !important;
}

.especial-buttons .active .white {
  color: var(--verde) !important;
}

#priceTag {
  display: flex;
  align-items: center;
  width: 100%;
}

#PersonalizedTokenPrice {
  width: 100%;
}

.green {
  color: #cafc4f !important;
  font-size: 18px !important;
}

.reed {
  color: var(--rojo);
}

#stopLossCounter *,
#takeProfitCounter * {
}

#stopLossCounter,
#takeProfitCounter {
  display: flex;
  align-items: center;
}

.Exchange-left {
  margin-right: 0.5%;
  min-height: 645px;
}

.resize {
  cursor: col-resize;
  pointer-events: auto;
}

.resize::before {
  content: "";
  position: absolute;
  top: 0;
  width: 2px;
  height: 105%;
  background-color: var(--gris-3);
  margin-left: -9px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.resize:hover::before {
  opacity: 1;
  /* Mostrar la línea blanca al hacer hover */
}

.resize:active::before {
  opacity: 1;
  /* Mostrar la línea blanca al hacer hover */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  /* Transparencia */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  /* Asegura que el modal esté por encima del resto del contenido */
}

@media (screen: 1920px) {
  .resize {
    border: 4px solid var(--negro);
  }
}

@media (screen: 2420px) {
  .resize {
    border: 5px solid var(--negro);
  }
}

.ExchangeChart.tv .ExchangeChart-top {
  width: 100%;
  padding: 0;
}

#actions-buttons i {
  color: var(--gris-2);
}

.Exchange-right {
  min-width: 340px !important;
  max-width: 23% !important;
  margin-top: -10px;
}

.Exchange-left {
  min-width: 77% !important;
  max-width: 90% !important;
}

.App-box,
.App-card,
.App-card-primary,
.App-box-highlight,
.App-box-solid {
  border-radius: 0px !important;
}

.Exchange-swap-market-box {
  text-transform: uppercase;
  background-color: var(--fondo-1-negro) !important;
  margin-top: -10px;
  color: var(--gris-2);
}

.Exchange-swap-market-box * {
  font-family: "Lato";
}

.box-select * {
  background: var(--fondo-2-gris);
  flex-basis: 25%;
  width: 25%;
  text-align: left;
  font-size: 12px !important;
  text-transform: uppercase;
  padding: 0 4px;
  color: var(--gris-2);
  font-weight: 600 !important;
  letter-spacing: 0.03em !important;
  line-height: 28px;
  cursor: pointer;
}

.box-select .active {
  color: var(--blanco) !important;
}

.m-top-2 {
  margin-top: 2rem;
}

#stopLossBoxModal,
#takeProfitBoxModal {
  background-color: var(--gris-4);
  height: 40px;
  border-radius: 5px;
}

.position-share-modal * {
  font-family: "Lato";
}

#buttonSL,
#buttonTP {
  border: none;
  outline: none;
  margin-left: 10px;
  padding: 0px 25px;
}

.label-modal {
  font-weight: normal;
  margin-left: 5px;
  /* margin-bottom: 5px; */
  color: var(--gris-2);
}

#stopLossBox,
#takeProfitBox {
  background-color: var(--fondo-1-negro) !important;
}

input[readonly] {
  cursor: default;
}

#takeProfitBox,
#stopLossBox {
  background-color: var(--gris-3);
  border-radius: 3px;
}

#info-label {
  width: 100% !important;
  text-align: left;
}

.gray {
  color: var(--gris-2);
  font-weight: bolder;
  cursor: not-allowed;
}

.white {
  color: var(--blanco);
  /* transform: rotate(45deg); */
  cursor: pointer;
}

.notAllowed {
  cursor: not-allowed;
}

.allowed {
  cursor: pointer;
}

#info-label-div {
  display: block;
}
