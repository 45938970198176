.BuyGMXGLP {
    background: #101124;
    justify-content: space-between;
}

.BuyGMXGLP-container {
    padding: 0 0 3.1rem 0;
    width: 100%;
}

.BuyGMXGLP-container .section-title-content {
    justify-content: flex-start;
}

@media (max-width: 400px) {
    .BuyGMXGLP-container {
        padding-left: 1.6rem!important;
        padding-right: 1.6rem!important;
    }
}
