.Referrals {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.referrals-title {
  color: var(--NARANJA, #fc9344);
  text-align: center;
  font-family: "Lato";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 6.4px;
  margin-top: 40px;
}

.highlighted-value-img {
  width: 30px;
}

.referrals-desc {
  overflow: hidden;
  color: var(--GRIS-1, #dedede);
  text-align: center;
  text-overflow: ellipsis;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03px;
}

.referrals-table-content {
  display: flex;
  width: 956px;
  height: 127px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  flex-shrink: 0;
}

.referrals-table-content-v2 {
  display: flex;
  flex-direction: column;
  width: 956px;
  height: 127px;
  margin: 0 auto;
  flex-shrink: 0;
}

.referrals-table-content-short-box {
  display: flex;
  width: 30%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid var(--GRIS-3, #3c3d43);
  height: 180px !important;
}

.referrals-table-content-short-box h3 {
  color: var(--VERDE-LIMA, var(--VERDE-LIMA, #cafc4f));
  text-align: center;
  font-family: Lato;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 4px;
}

.referrals-table-content-short-box p {
  color: var(--GRIS-1, #dedede);
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.referrals-table-content {
  display: flex;
  width: 956px;
  height: 127px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  flex-shrink: 0;
}

.referral-code-div {
  display: flex;
  padding: 32px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border: 1px solid var(--GRIS-3, #3c3d43);
  background: var(--FONDO-2-GRIS, #19181d);
  min-height: 310px !important;
  max-height: 310px !important;
  height: 310px !important;
}

.referral-code-div h3 {
  color: var(--GRIS-1, #dedede);
  text-align: center;
  font-family: Lato;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.039px;
}

.referral-code-div p {
  color: var(--GRIS-1, #dedede);
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.056px;
}

.referral-code-div input {
  display: flex;
  height: 43px;
  padding: 0px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 2px;
  background: var(--FONDO-1-NEGRO, #121117);
}

.referral-code-div button {
  display: flex;
  width: 398px;
  padding: 8px 40px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: var(--VERDE-LIMA, #cafc4f);
  outline: none;
  border: none;
  text-transform: uppercase;
}

.buttonConnect {
  display: flex;
  width: 398px;
  padding: 8px 40px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: var(--VERDE-LIMA, #cafc4f);
  outline: none;
  border: none;
  color: black !important;
  cursor: pointer;
}

.noneDisplay {
  display: none !important;
  margin-right: 25px;
}

.referred-table {
  width: 50vw;
  border: 1px solid var(--GRIS-3, #3c3d43);
  text-align: center;
}

.referred-table thead {
  background: var(--GRIS-4, #222);
}

.referred-table tbody {
  background: var(--FONDO-2-GRIS, #19181d);
}

.referred-table th,
.referred-table td {
  border: 1px solid var(--GRIS-3, #3c3d43);
}
