@import url("../../App/Vars.css");

.vault-container {
  background-color: var(--fondo-2-gris);
  width: 100%;
  padding: 20px;
  border: 1px solid;
  border: 1px solid var(--gris-4);
}

.vault-container input {
  text-align: right;
  background-color: var(--fondo-1-negro);
  width: 100%;
  border: 1px solid;
  border: 1px solid var(--gris-4);
}

.withdraw-container {
  background-color: var(--fondo-2-gris);
  width: 100%;
  padding: 20px;
  border: 1px solid;
  border: 1px solid var(--gris-4);
}

.withdraw-part1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.withdraw-part1-info {
  width: 100%;
}

.withdraw-part1-info div {
  width: 100%;
}

.withdraw-part1-info div div {
  width: 100%;
}

#withDrawInput::placeholder {
  text-align: right;
}



.withdraw-part2 input {
  background-color: var(--fondo-1-negro);
  width: 100%;
  border: 1px solid;
  border: 1px solid var(--gris-4);
  margin-bottom: 20px;
}

.button-end-vault {
  width: 200px;
  background-color: #cafc4f !important;
  color: black;
  border: none;
  padding: 10px 5px;
}

.vault-end-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.max-button {
  background-color: none;
  border: none;
}

.button-withdraw {
  border: 1px solid #cafc4f !important;
  background-color: transparent !important;
  color: #DEDEDE !important;
}