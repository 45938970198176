@import url("../../App/Vars.css");

.general-container {
  min-height: 94.4vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -60px;
}

.range {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.user-stat {
  color: var(--verde-lima);
  text-align: center;
  font-family: "Lato";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03px;
}

.title-text {
  color: var(--gris-1, #dedede);
  font-family: "Lato";
  margin-bottom: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}

.red-text {
  display: flex;
  margin-bottom: 8px;
  color: var(--gris-1, #dedede);
  font-family: "Lato";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2px;
}

.range .button-range {
  display: flex;
  width: auto;
  height: 24px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  background: var(--fondo-1-negro, #121117);
  outline: none;
  border: none;
  color: var(--gris-2);
  transition: 300ms;
}

.range .button-range:hover {
  color: var(--gris-1);
}

.active {
  color: var(--verde-lima) !important;
}

.block-stat {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 25px;
}

.title-stat {
  color: var(--gris-1, #dedede);
  text-align: center;
  font-family: "Lato";
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.039px;
  margin-bottom: 30px;
  margin-top: 80px;
}

.stat {
  display: flex;
  width: 25%;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid var(--gris-3, #333);
  background: var(--fondo-2-gris, #19181d);
}

.text-stat {
  color: var(--gris-2, #808080);
  text-align: center;
  font-family: "Lato";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}

.number-stat {
  color: var(--verde-lima);
  text-align: center;
  font-family: "Lato";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03px;
}

.blocks-stat {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Accordion Stats */
.accordion-stats {
  width: 100%;
  margin-bottom: -17px;
}

.accordion-title {
  display: none;
  margin: 0 auto;
  justify-content: space-between;
  padding: 20px;
  width: 60%;
  background-color: #19181d;
  border: 1px solid var(--gris-3);
  border-radius: 3px;
  margin-bottom: 10px;
}

.accordion-title button {
  background-color: transparent;
  color: white;
  border: none;
}

.accordion-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stats-title {
  color: var(--NARANJA, #fc9344);
  text-align: center;
  font-family: "Lato";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 6.4px;
  margin-top: 100px;
  margin-bottom: 20px;
}

@media (max-width: 950px) {
  .accordion-title {
    display: flex;
    width: 80%;
  }

  .block-stat {
    width: 80%;
    flex-direction: column;
  }

  .stat {
    width: 100%;
  }
}
