@import url("../../App/Vars.css");

.DashboardV2 {
  padding-top: 4.65rem;
  background: var(--fondo-1-negro);
}

.DashboardV2 * {
  z-index: 10;
}

#backgroundGreen {
  position: absolute;
  top: 25vh;
  left: 25vw;
  width: 50vw;
  height: 50px;
  border-radius: 1209px;
  opacity: 0.5;
  background: var(--green, #000);
  filter: blur(150px);
}

.DashboardV2-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  margin-top: 0.8rem;
}

.DashboardV2-token-cards {
  margin-top: 0.8rem;
}

.token-table-wrapper {
  border: 1px solid #1e2136;
  border-radius: 0.4rem;
  background: #101010;
  padding: 0 1.5rem;
  text-align: left;
}

.token-table-wrapper .App-card-title {
  justify-content: unset;
}

.token-table-wrapper .App-card-title img {
  margin-top: 0.3rem;
  display: inline-flex;
  margin-left: 0.8rem;
}

.App-card-title-icon {
  display: flex;
  margin-left: 1.2rem;
}

.App-card-title-icon img + img {
  margin-left: 0.6rem;
}

.stats-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.stats-wrapper .App-card {
  width: 100%;
  display: flex;
}

.stats-block {
  width: 100%;
}

.stats-piechart {
  margin: -1rem;
  margin-left: 1.5rem;
}

.stats-block--glp .stats-label {
  grid-row-gap: 0;
}

.stats-label {
  font-size: 1.4rem;
  line-height: 1.6rem;
  border-radius: 0.4rem;
  padding: 1.05rem;
  letter-spacing: 0.4px;
  text-align: left;
  z-index: 1;
  background: linear-gradient(90deg, #0b0b0f 0%, rgba(10, 13, 28, 1) 100%);
  cursor: pointer;
}

.stats-label-color {
  width: 0.4rem;
  height: 100%;
  margin-right: 1.5rem;
}

.Available-network-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.8rem;
}

.Available-network-group .Tooltip {
  display: flex;
}

.Available-network-group .Available-network {
  display: flex;
}

.Available-network-group .Available-network img {
  filter: grayscale(100%);
}

.Available-network-group .Tooltip-handle.active .Available-network img {
  filter: unset;
}

.Available-network-group .Available-network img:hover {
  filter: unset;
}

.token-table .token-symbol-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 4.65rem;
}

.DashboardV2-token-cards .token-table td:nth-child(3),
.DashboardV2-token-cards .token-table th:nth-child(3) {
  width: 20rem;
}
.mobile-token-card {
  display: flex;
  align-items: center;
}

.token-symbol-text {
  line-height: 1;
}

.mobile-token-card > img {
  margin-right: 0.8rem;
}

.mt-10 {
  margin-top: 10vh;
}

.mt-15 {
  margin-top: 15vh;
}

.mt-25 {
  margin-top: 25vh;
}

.mt-45 {
  margin-top: 45vh;
}

.w-95 {
  width: 95%;
}

#FondoSmartTrading {
  position: absolute;
  width: 100vw;
  height: 150vh;
  left: 0px;
  top: 190vh;
}

.smartTrading {
  height: 940px;
  background-image: url("../../img/BgSmartTrading.png");
  margin-top: 300px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  z-index: 1;
}

#FirstBlock {
  margin-top: -50px;
}

.page-title-header {
  font-size: 4rem !important;
}

.page-title-description {
  font-size: 2rem !important;
  /* width: 42rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin: auto; */
}

.gold-reveal {
  margin-top: 150px;
}

.gold-reveal-top {
  color: #eed183;
  font-weight: 700;
  font-size: 1.8rem;
}

.gold-reveal-bottom {
  color: #9d7d44;
  font-weight: 400;
  font-size: 1.2rem;
}

.gold-reveal-actions {
  border-radius: 20px;
  width: 38vw;
  padding: 0px 20px;
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  background-color: #181818;
}

.gold-reveal-input-subscription {
  width: 85%;
  padding: 8px 15px;
  border-radius: 20px;
  font-family: "Lato";
}

#contenedor-img * {
  z-index: 0;
}

.gold-reveal-input-subscription::placeholder {
  text-align: left;
  font-size: 16px;
  color: #444;
}

.gold-reveal-span {
  font-size: 1.3rem;
  width: 15%;
  cursor: pointer;
  font-family: "Lato";
  margin-top: 15px;
  font-weight: bolder;
  height: 31px;
}

.gold-reveal-span:hover {
  color: #1e924d;
}

#DiscButton {
  background: url("../../img/DiscordDefault.svg") no-repeat center;
  margin: auto;
  height: 5rem;
  width: 27%;
  cursor: pointer;
}

#DiscButton:hover {
  background-image: url("../../img/DiscordVariant2.svg");
}

#DiscButtonSpan {
  visibility: hidden;
}

#GoToDocsButton {
  padding: 1rem 12rem;
  border: 0px;
  color: #9ba5a3;
  font-size: 20px;
  cursor: pointer !important;
  z-index: 900;
  border-width: 3px;
  border-style: solid;
  border-image: linear-gradient(to right, #403c1a, #1a4027) 1;
  text-decoration: none;
}

#smartTradingEndText {
  font-size: 1.5rem;
}

.Footer * {
  z-index: 0;
}

.launch-celebration {
  background: radial-gradient(circle, rgb(12, 30, 95) 0%, rgb(0, 1, 4) 100%);
  padding: 1rem 0;
  position: fixed;
  width: 100vw;
  bottom: -3rem;
  z-index: 2;
  opacity: 1 !important;
}

.goToPitchdeck {
  margin-top: 100px;
  text-align: center;
  margin-bottom: 400px;
}

.partners-logos * {
  z-index: 0;
}

.email-input-subscription,
.email-button-subscription {
  height: 4rem;
  padding: 0px;
}

.launch-celebration-header {
  font-size: 1.7rem;
  color: #9747ff;
}

.softGrey {
  color: #9a9797;
}

@media (max-width: 1024px) {
  .stats-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
  }

  .stats-wrapper .App-card {
    max-width: unset !important;
    margin: 0;
  }
}

@media (max-width: 1100px) {
  .token-table-wrapper {
    display: none;
  }
}

@media (max-width: 900px) {
  .DashboardV2-cards {
    grid-template-columns: 1fr;
  }

  .DashboardV2-token-cards .token-grid {
    padding-top: 0;
  }
}

@media (max-width: 660px) {
  .stats-wrapper .App-card {
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  .stats-piechart {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
  }
}
