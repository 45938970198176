:root {
  --blanco: #ffffff;
  --gris-1: #dedede;
  --gris-2: #808080;
  --gris-3: #333333;
  --gris-4: #222222;
  --fondo-2-gris: #19181d;
  --fondo-1-negro: #111117;
  --negro: #000000;
  --rojo: #e23e3e;
  --verde: #148a63;
  --verde-btn: #148a63;
  --amarillo: #d1a35a;
  --verde-lima: #cafc4f;
}
