@font-face {
  font-family: "Digital Numbers";
  src: url("../../fonts/DigitalNumbers/DigitalNumbers-Regular.ttf");
}

@import url("../../App/Vars.css");

@import url("https://fonts.googleapis.com/css2?family=Chelsea+Market&display=swap");

.fuckies {
  background-image: url("../../img/fuckies-back.jpg") !important;
  width: 100%;
  min-height: 92vh;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fuckies * {
  font-family: "Chelsea Market";
}

.fuckies-container {
  margin: 0 auto;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dona-rosa {
  z-index: 0;
  position: absolute;
  right: 0;
}

.dona-verde {
  z-index: 0;
  position: absolute;
  left: 0;
}

.fuckies-element .element-1 {
  display: flex;
  flex-wrap: wrap;
  background-color: #fbc240;
  width: 80%;
  border: 4px solid black;
  border-radius: 5px;
}

.fuckies-element .element-1 div {
  width: 50%;
  border: 4px solid #ffd573;
  text-align: center;
  color: black;
  font-weight: bolder;
  border-radius: 5px;
  height: 40px;
}

.fuckies-element .element-1 div p {
  font-family: "Chelsea Market";
  line-height: 30px;
}

.fuckies-elements {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
}

.fuckies-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.fuckies-info .info-number-fuckies {
  background-image: url("../../img/fuckies/back-numbers-fuckies.png");
  background-size: cover;
  width: 45%;
}

.fuckies-info .info-number-usdt {
  background-image: url("../../img/fuckies/back-numbers-usdt.png");
  background-size: cover;
  width: 45%;
}

.fuckies-info .info-number-fuckies p {
  font-family: "Digital Numbers";
  font-size: 3rem;
  text-align: right;
  color: #ff0000;
  margin-top: 12px;
  margin-right: 51px;
  letter-spacing: 0.6px;
}

.fuckies-info .info-number-usdt p {
  font-family: "Digital Numbers";
  font-size: 3rem;
  text-align: right;
  color: #ff0000;
  margin-top: 12px;
  margin-right: 55px;
  letter-spacing: 0.4px;
}

.fuckies-element {
  width: 100%;
  /* border: 4px solid black; */
  /* border-radius: 5px; */
  /* background: #F8BCCA; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 300px;
  height: 250px;
  padding: 20px;
}

.element-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 30px;
  margin: 20px 0px;
  border-radius: 5px;
}

.element-2 img {
  width: 100%;
}

.element-2 div {
  width: 11.1%;
  height: 22px;
}

.element-3 {
  font-family: "Chelsea Market";
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  color: black;
  text-align: center;
}

.fuckies-button {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
  width: 100%;
}

.fuckies-button button {
  background: none;
  border: none;
}

.fuckies-button button {
  width: 100%;
  font-family: "Chelsea Market";
}

.fuckies-button .first-button {
  color: var(--gris-1);
}

.fuckies-button .last-button {
  margin: 0 auto;
  width: 400px;
  height: 100px;
  margin-top: 20px;
  background-image: url("../../img/fuckies-buttons/CASH-OUT-OFF.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.fuckies-button .last-button:hover {
  background-image: url("../../img/fuckies-buttons/CASH-OUT-HOVER.png");
}

.fuckies-buttons-operation {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.btn-operation {
  display: flex;
  justify-content: space-around;
  width: 33%;
  /* padding: 0px 20px; */
}

.btn-operation:last-child {
  width: 27%;
}

.btn-operation:last-child button {
  width: 100%;
}

.btn-op {
  width: 40%;
}

.btn-op img,
.btn-go img {
  width: 100%;
}

.btn-go {
  width: 55%;
  margin: 0 auto;
}
