#tradingview_a785c {
  max-height: 75vh;
  min-width: 100%;
  height: 100%;
}

#tradingview_3dfcc-wrapper {
  height: 100%;
  max-height: 75vh;
  width: 100% !important;
  min-width: 100% !important;
}
