@import url("../../App/Vars.css");
.container-banner:first-child {
  margin-top: -4px;
}

.container-banner {
  margin-bottom: 5px !important;
}

.banner-maintenance {
  margin-top: 5px;
  width: 100%;
  z-index: 00;
  background-color: var(--rojo);
  padding: 1px 0px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.banner-maintenance span {
  font-size: 14px;
  font-style: italic;
}
