@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
@import url("../../App/Vars.css");

.chart-token-selector {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.checkbox-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-item input {
  margin-right: 10px;
}

.input-checkbox {
  appearance: none;
  /* Cambiar el color de fondo a verde cuando está marcado */
  background-color: var(--gris-3);
  width: 3px;
  height: 3px;
  padding: 6px;
  margin-top: 3px;
}

.input-checkbox:checked {
  appearance: none;
  /* Cambiar el color de fondo a verde cuando está marcado */
  background-color: var(--verde);
  width: 3px;
  height: 3px;
}

.chart-token-selector--current {
  font-size: 2.15rem;
  margin-right: 4rem;
}

.chart-token-menu-items.chart-token-menu-items {
  top: 6rem;
  right: unset;
  left: 0;
}

.chart-token-menu-items .menu-item {
  font-size: 1.4rem;
  height: 3.4rem;
  cursor: pointer;
  color: var(--gris-2);
}

.chart-token-menu-items .menu-item2 {
  font-size: 1.4rem;
  height: 3.4rem;
  cursor: pointer;
}

.stylePairsList {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}

.desplegable {
  font-family: "Lato";
  height: 100%;
  z-index: 900;
  font-weight: 300;
}

.desplegable .desplegable-selected * {
  font-family: "Lato" !important;
}

.chart-token-menu-items {
  width: 500px;
  margin-left: 3px;
  margin-top: -8px;
}

.chart-token-menu {
  margin-top: 11px;
  position: absolute;
  z-index: 100;
  background-color: var(--fondo-1-negro);
  margin-left: -3.2px;
  margin-top: 9px;
}

.desplegable-extra-elements {
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--gris-4) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: var(--gris-2);
  width: 502px;
  margin-right: 3px;
}

.desplegable-extra-elements span {
  font-size: 16px;
  width: 60%;
  margin-top: 2px;
  margin-left: 5px;
}

.desplegable-extra-elements .input[type="checkbox"] {
  margin-top: 5px;
}

.desplegable-extra-elements .elements input::placeholder {
  font-size: 16px;
}

.desplegable-extra-elements .elements {
  display: flex;
  align-items: center;
  width: 33.3%;
}

.desplegable-info {
  font-family: "Lato";
}

.desplegable-extra-elements i {
  margin-left: 5px;
  font-size: 18px;
  margin-top: 5px;
}

#SearchToken {
  background-color: var(--fondo-1-negro);
  height: 45px;
  width: 170px;
  margin-left: 5px;
  line-height: 45px;
  border-radius: 5px;
}

.desplegable-selected {
  max-height: 70vh;
  overflow-y: scroll;
  display: flex;
  width: 495px;
  justify-content: space-between;
}

.desplegable-selected::-webkit-scrollbar-thumb {
  background-color: var(--gris-2);
  border-radius: 20px;
}

.dropdown-toggle-menu {
  border: none;
  background: #222;
  color: #616161;
  font-weight: 300;
  font-family: Lato;
  font-size: 16px;
  margin-left: 0px;
}

.span-element {
  font-size: 16px;
  font-family: "Lato";
  font-weight: 400;
  margin-left: 5px;
}

.star {
  font-family: FontAwesome;
  content: "\f005";
}

.price-gray {
  color: var(--gris-2);
}

.price-green {
  color: var(--verde);
}

.price-red {
  color: var(--rojo);
}

.pairPriceSpan {
  text-align: right;
  margin-left: -10px;
  padding: 0px 10px;
  width: 100%;
  font-size: 14px;
  font-family: "Lato";
  font-weight: 400;
}

.pairNameSpan {
  font-size: 16px;
  font-family: "Lato";
  font-weight: 400;
  margin-left: 5px;
}

@media (max-width: 520px) {
  .chart-token-menu-items,
  .chart-token-menu {
    width: 100vw !important;
  }

  .desplegable-extra-elements {
    width: 100vw;
    margin-right: 5px;
  }

  #SearchToken {
    width: 80px;
    margin-left: 45px;
  }

  .dropdown-toggle-menu {
    font-size: 10px !important;
  }

  .desplegable-extra-elements span {
    font-size: 12px;
    width: 50%;
    margin-right: 5px;
  }

  .menu-item p {
    font-size: 10px !important;
  }

  .pairPriceSpan,
  .pairNameSpan {
    width: 100%;
    font-size: 12px;
  }

  .chart-token-menu-items .menu-item {
    height: 35px;
  }

  .desplegable-selected {
    width: 100vw;
    justify-content: space-between;
  }
}

@media (max-width: 520px) {
  .chart-token-menu-items,
  .chart-token-menu {
    width: 100vw;
    overflow-x: hidden !important;
  }

  .desplegable-extra-elements {
    width: 100vw;
    margin-right: 5px;
    overflow-x: hidden !important;
  }

  #SearchToken {
    width: 80px;
    margin-left: 4px;
  }

  .dropdown-toggle-menu {
    font-size: 10px !important;
  }

  .desplegable-extra-elements span {
    font-size: 14px;
    width: 50%;
    margin-right: 5px;
  }

  .menu-item p {
    font-size: 12px !important;
  }

  .pairPriceSpan {
    width: 100%;
    font-size: 14px;
  }

  .chart-token-menu-items .menu-item {
    height: 35px;
  }

  .desplegable-selected {
    width: 100vw;
    justify-content: space-between;
    overflow-x: hidden !important;
  }

  .pairNameSpan {
    margin-left: 15px;
  }
}
