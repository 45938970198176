.TradeHistory-row {
  font-size: 1.4rem;
  padding: 1.5rem;
  margin-bottom: 0.8rem;
  background-color: transparent !important;
  width: 100%;
}

.TradeHistory {
  margin-top: -30px;
}

.TradeHistory-time {
  font-size: 1.25rem;
  margin-bottom: 0.155rem;
}

.prev-button,
.next-button,
.text-button {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  background: var(--GRIS-3, #333);
  color: var(--GRIS-1, #dedede);
  text-align: right;
  font-family: "Lato";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  outline: none;
  border: none;
  margin: 0px 5px;
}
