@import url("../../App/Vars.css");

.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: 1.4rem;
  border-radius: 3px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.7);
  background: #101010;
  box-shadow: inset 0px 0px 30px 5px rgba(255, 255, 255, 0.01);
}

.Tab-option-icon {
  margin-right: 0.465rem;
  opacity: 0.7;
}

.Tab.block .Tab-option {
  text-transform: uppercase;
  width: 50%;
  text-align: center;
  background-color: var(--fondo-2-gris);
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  font-size: 1.5rem;
}

.Tab.block .Tab-option:hover {
  color: rgba(255, 255, 255, 1);
}

.Tab.block .Tab-option.active {
  text-transform: uppercase;
  opacity: 1;
  pointer-events: none;
  background: #1a4027;
  color: var(--gris-1);
}

.white {
  color: white !important;
}

.Tab.block .Tab-option.active.green {
  text-transform: uppercase;
  background: #148a63;
  color: var(--gris-1) !important;
}

.Tab.block .Tab-option.active.reed {
  text-transform: uppercase;
  background: #e23e3e;
  color: var(--gris-1) !important;
}

.Tab.block .Tab-option.active.blue {
  background: linear-gradient(to right, #0f684b, #131b30);
  border-bottom: 2px solid #2d3fe4;
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline .Tab-option {
  justify-content: start;
  cursor: pointer;
  display: inline-block;
  font-size: 1.5rem;
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
}
