@import url("../../App/Vars.css");

.BuyGMXGLP {
  background: #101124;
  justify-content: space-between;
}

.BuyGMXGLP-container {
  padding-top: 4.65rem;
  padding-bottom: 3.1rem;
  width: 100%;
}

.BuyGMXGLP-panel {
  padding-top: 0;
  padding-bottom: 3.875rem;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  display: flex;
  justify-content: space-between;
}
.BuyGMXGLP-panel .App-card:first-child {
  margin-right: 2rem;
}

.BuyGMXGLP-panel .App-card {
  max-width: 59rem;
  width: 100%;
  height: fit-content;
  flex: auto;
}

.BuyGMXGLP-panel .App-card.no-height {
  height: unset;
}

.BuyGMXGLP-panel:last-child {
  padding-bottom: 0;
}

.BuyGMXGLP-description {
  letter-spacing: 0px;
  color: #a9a9b0;
}

.BuyGMXGLP-description a {
  text-decoration: none;
  position: relative;
}

.bridge-options {
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: 1fr 1fr;
  margin-top: 1.5rem;
}
.buy-gmx {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  margin: 3.1rem 0;
}
.alternative-bridges .transfer-eth-icon {
  width: 5.58rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.direct-purchase-options {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  margin: 3.1rem 0;
}

.GMX-btn {
  display: flex;
  align-items: center;
  background: #ffffff0a;
  border: 1px solid #ffffff14;
  border-radius: 0.4rem;
  text-decoration: none;
  height: 4.65rem;
  padding: 0.8rem 1.5rem;
  margin: 3.1rem 0;
}

.GMX-btn:hover {
  background: #ffffff14;
}

.GMX-btn:focus,
.GMX-btn:active {
  background: #ffffff1f;
}

.GMX-btn-icon-block {
  display: flex;
  margin-right: 1.165rem;
}

.GMX-btn-icon {
  display: flex;
  position: relative;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
}

.GMX-btn-icon-network {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
}

.GMX-btn-label {
  letter-spacing: 0px;
  color: var(--gris-1);
  font-size: 1.85rem;
  line-height: 2.325rem;
}

.GMX-block-section {
  display: flex;
  justify-content: center;
}

.display-bg {
  display: block;
}

.display-sm {
  display: none !important;
}

.BuyGMXGLP-panel .buy-card {
  display: flex;
  flex-direction: column;
}

.BuyGMXGLP-panel .buy-card:first-child,
.BuyGMXGLP-panel .section-title-block:first-child {
  margin-right: 2rem;
}

.card-title {
  font-size: 3.41rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

@media (max-width: 800px) {
  .display-bg {
    display: none !important;
  }
  .display-sm {
    display: block !important;
  }
  .card-details {
    display: flex;
    flex-direction: column;
  }
  .section-title-block {
    margin-bottom: 1.5rem !important;
  }
  .BuyGMXGLP-panel {
    flex-direction: column;
  }
  .BuyGMXGLP-panel .App-card:first-child {
    margin-right: 0;
    margin-bottom: 2rem;
  }
  .BuyGMXGLP-panel .App-card {
    max-width: 100%;
  }
  .BuyGMXGLP-panel .buy-card:first-child,
  .BuyGMXGLP-panel .section-title-block:first-child {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

@media (max-width: 450px) {
  .alternative-bridges {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 400px) {
  .BuyGMXGLP-container {
    padding-left: 1.6rem !important;
    padding-right: 1.6rem !important;
  }

  .BuyGMXGLP-description {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .bridge-options {
    grid-template-columns: 1fr;
  }
}
