.menu-items:focus-visible {
  border: 1px solid #262638;
}
.address-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--gris-1) !important;
  border: none !important;
  outline: none !important;
  border: 1px solid #148a63;
}

.user-address {
  margin-left: 1rem;
  margin-right: 1rem;
}

.App-header-user-address:hover {
  background: #808aff14;
}

.menu-items {
  width: 180px;
  padding: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  top: 4.3rem;
  transform-origin: top right;
  background: #0f0f12;
  list-style: none;
  cursor: pointer;
  outline: none;
  z-index: 100;
}
.onHoverMenuItem:hover {
  color: #eee !important;
}
.menu-item {
  display: flex !important;
  align-items: center;
  font-family: "Lato";
  font-size: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 1.4rem;
  padding: 0.85rem 0.8rem;
  border-radius: 0.4rem;
}
.menu-item:hover {
  background: #808aff14 !important;
  border-radius: 0.4rem;
  opacity: 1;
  color: white !important;
}
.menu-item:hover .network-dropdown-item-label {
  color: white;
}
.menu-item > p {
  margin: 0px;
  padding-left: 1rem;
}
.menu-item > a {
  display: inline-flex;
}

@media screen and (max-width: 370px) {
  .user-address {
    margin-left: 0;
  }
  .address-btn {
    display: flex;
    justify-content: space-between;
  }
  .user-avatar {
    display: none;
  }
}
