@import url("./Vars.css");

@media (max-width: 1100px) {
  .Exchange-should-show-position-lines {
    margin-bottom: 40px;
  }

  .scroll-buttons {
    width: 99% !important;
  }

  .Exchange-content {
    display: flex !important;
    flex-direction: column;
  }

  .Exchange-right {
    min-width: 100% !important;
    width: 100% !important;
    margin-right: -10px !important;
    margin-left: 5px !important;
    margin-bottom: -120px;
  }

  .element-position-stuck {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .element-par-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -0px !important;
  }

  .element-par-img span {
    margin-bottom: 7px;
  }

  .Exchange-left {
    width: 100vw !important;
    max-width: 100% !important;
    margin-right: -20px !important;
    min-height: 450px;
  }

  .Exchange-content {
    margin: 0 auto;
    max-width: 99% !important;
    width: 99% !important;
  }

  #tradingview_a785c {
    width: 100%;
    margin-right: -10px !important;
    height: 48vh !important;
  }

  .resize {
    display: none;
  }

  .Exchange-lists-small {
    display: block;
  }

  .Exchange-lists-large {
    display: none;
  }

  .Exchange-swap-box-inner {
    margin-top: -3px !important;
  }

  .Checkbox {
    margin-bottom: -10px !important;
  }

  .Exchange-list-tabs {
    margin-bottom: 25px !important;
  }

  .favs-par {
    margin-left: 20px;
  }
}

@media (max-width: 1100px) {
  .Exchange-should-show-position-lines {
    margin-bottom: 20px;
  }

  .Exchange-right {
    margin-bottom: -230px;
  }

  .Exchange-list-tabs {
    margin-top: 100px !important;
  }

  .Exchange-empty-positions-list-note {
    margin-top: 10px;
  }

  #PositionsListHeader .hide,
  #PositionsList .hide,
  #OrdersList .hide,
  #OrdersListHeader .hide {
    display: none;
  }

  #PositionsListHeader div,
  #PositionsList div {
    width: 30% !important;
  }

  #PositionsList img {
    margin-bottom: 5px;
  }

  #actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #actions-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1490px) {
  .navbar .container a,
  .navbar .container span,
  .navbar .container p,
  .navbar .container button {
    font-size: 12px;
    font-family: "Lato";
    font-weight: 500;
    margin-left: 0px !important;
    text-decoration: none;
    color: white;
  }

  #nav-dropdown {
    margin-left: -5px !important;
  }

  .dropdown-menu  .dropdown-menu-dark #nav-dropdown {
    margin-left: -5px !important;
  }

  .inactive-link {
    color: white !important;
  }

  .dropdown-item{
    padding: 5px 10px !important;
  }

  .Exchange-right {
    min-width: 290px !important;
  }

  .ExchangeChart-additional-info {
    width: 210px;
  }
}

@media (max-width: 1350px) {
  .Exchange-right {
    min-width: 98vw !important;
  }

  .navbar {
    z-index: 900;
    display: flex;
    align-items: start;
    height: 100vh;
    background-color: rgb(18, 18, 18, 0.8) !important;
    color: var(--blanco) !important;
  }

  .Exchange-right {
    margin-top: 5px;
  }

  .Resizable {
    margin-bottom: 5px;
  }

  .navbar .container {
    height: 35%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    margin-left: 20px;
  }

  .pitchdeck {
    text-decoration: none;
    margin-left: 7px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media (max-width: 500px) {
  .Exchange-swap-box-inner {
    margin-top: -10px !important;
  }
  .Exchange-list {
    margin-top: 15px;
    height: auto;
    margin-bottom: 20px;
  }

  .Exchange-list div {
    margin-top: 7px;
  }

  #PositionsList {
    margin-bottom: 5px !important;
  }

  .Exchange-list-tabs {
    margin-top: 195px !important;
  }
}

@media (max-width: 650px) {
  .header-btn {
    font-size: 12px;
    padding: 5px 5px;
  }

  .Exchange-right {
    margin-top: 0px;
  }

  .network-dropdown {
    padding: 0px 5px;
  }
}

@media (max-width: 920px) {
  .TradingTitle h1 {
    font-size: 30px !important;
  }

  .TradingButtons p {
    font-size: 14px !important;
  }
}

@media (max-width: 720px) {
  .TradingTitle h1 {
    font-size: 20px !important;
  }

  .TradingButtons p {
    font-size: 12px !important;
  }
}

@media (max-width: 500px) {
  .competition-info {
    font-size: 12px !important;
  }

  .TradingTitle h1 {
    text-align: center;
  }

  .TradingButtons p {
    text-align: center;
  }

  .header-btn {
    font-size: 9px;
    width: 52px;
  }

  .App-header-user-address .user-address {
    margin: 0 !important;
  }

  .d-none-small {
    display: none !important;
  }

  .dropdown-menu .dropdown-item {
    padding: 10px !important;
  }

  #trading-contest {
    margin-top: 10px !important;
  }

  .padding-link {
    padding: 10px !important;
  }
}

@media (max-width: 400px) {
  .Referrals {
    height: 195vh;
  }
  .favs-chart {
    margin-top: 10px;
  }
  .responsive-hide {
    display: none;
  }
  .btn-loyalty {
    padding: 10px !important;
    font-size: 12px;
  }
  #SearchToken {
    height: 30px;
    margin-top: 7px;
  }
  .desplegable-extra-elements i {
    margin-top: 7px;
  }
  .desplegable-extra-elements span {
    font-size: 10px !important;
  }
  .desplegable .desplegable-selected * {
    font-size: 10px !important;
  }

  .desplegable .desplegable-selected img {
    margin-bottom: 13px;
  }
  .referrals-title {
    font-size: 40px !important;
  }
  .referrals-table-content {
    flex-direction: column;
    max-width: 100vw;
  } 

  .referral-code-div {
    margin-top: 370px;
    width: 90vw !important;
    margin-left: 20px;
    min-height: 410px !important;
    max-height: 410px !important;
  }

  .referral-code-div {
    height: 410px !important;
  }

  .buttonConnect {
    width: 100%;
  }

  .referrals-table-content-short-box {
    width: 90% !important;
  }

  .account-overview, .vault-info, .vault-container {
    width: 95vw;
  }

  .Footer-wrapper {
    height: 80% !important;
  }

  .info-eachpoch-vault p {
    font-size: 32px !important;
  }

  .Vault-list-tabs .Tab-option {
    font-size: 8px !important;
    padding: 0px 5px !important;
  }

  .Vault-list-tabs {
    flex-wrap: wrap;
    width: 95vw !important;
  }

  .dialog-withdraw {
    flex-direction: column;
    width: 100% !important;
  }

  .dialog-withdraw * {
    width: 100% !important;
  }

  .end-info-button {
    width: 100% !important;
  }

  .button-end-vault {
    width: 100% !important;
  }

  .Exchange-right {
    margin-top: -90px;
  }

  .Exchange-swap-box-inner {
    margin-top: 5px !important;
  }

  .otc-title {
    font-size: 40px !important;
  }

  .referred-table { 
    position: absolute;
    bottom: 2.5%;
    left: 5%;
    width: 90%;
  }

  .second-element {
    font-size: 10px !important;
  }

  .otc-info {
    margin-top: 140px !important;
  }
}