@import url("../../App/Vars.css");
.infoTrade {
  position: absolute;
  width: 350px;
  height: 550px;
  border-radius: 8px;
  background-color: #101010;
  padding: 40px;
  display: flex;
  font-weight: 300;
  flex-direction: column;
  align-items: center;
}

.infoTitle {
  width: 100%;
  text-align: center;
  margin-bottom: 35px;
}

.infoText {
  width: 100%;
}

.infoTitle h2 {
  font-size: 30px;
}

.infoTitle p {
  margin-top: 1px;
  font-size: 20px;
}

.infoItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.infoTrade button {
  background-color: #181818;
  border: none;
  color: #616161;
  padding: 3px 8px;
  margin-top: 20px;
}

.message {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #148a63;
  color: var(--gris-1);
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  animation: fadeOut 5s forwards;
}

.tradeTitle img {
  margin-right: 15px;
}

.tradeTitle p {
  font-size: 30px;
  font-weight: 300;
}

.tradeTitle span {
  color: #616161;
}

.tradeTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -10px;
  margin-right: 30px;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 20px;
  background-color: #101010 !important;
  border: none;
  color: var(--gris-1);
  font-size: 16px;
  cursor: pointer;
}

.closeButton:focus {
  outline: none;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
