@import url("../../App/Vars.css");
.Footer-wrapper {
  text-align: center;
  /* background: #0f0f12; */
  width: 100vw;
  background-color: #cafc4f !important;
  display: flex;
  align-items: center;
  height: 100px;
  justify-content: space-around;
  color: black !important;
}

.Footer img {
  filter: brightness(0) !important;
}

.Footer {
  background-color: #cafc4f !important;
  height: 100px;
  color: black !important;
}

.Footer-logo {
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer-logo img {
  height: 2.65rem;
  filter: brightness(0);
}

.Footer-social-link-block {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.Footer-link {
  color: black !important;
  font-size: 1.5rem;
  line-height: 1.85rem;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
  margin: 0px 1.5rem;
}

.Footer-social-link-block .App-social-link {
  margin: 0 3.2rem;
  display: flex;
  width: 3.2rem;
  height: 3.2rem;
  align-items: center;
  justify-content: center;
}

.Footer-social-link-block .App-social-link:hover img {
  filter: brightness(0) invert(1);
}

.Footer-copyright {
  padding: 1.6rem;
}

.Footer-copyright__text {
  font-family: Circular Std;
  font-size: 1.3rem;
  line-height: 1.3rem;
  letter-spacing: -0.41px;
  display: block;
}

.Footer-links {
  padding-bottom: 4rem;
  display: flex;
  justify-content: center;
}

.Footer-links > a:not(:last-child),
.Footer-links > .a:not(:last-child) {
  padding-right: 2rem;
}

.App-social-link:hover {
  /* width: 3rem;
  filter: invert(40%) sepia(100%) saturate(354%) hue-rotate(92deg) brightness(94%) contrast(91%); */
  filter: invert(41%) sepia(17%) saturate(1838%) hue-rotate(91deg) brightness(106%) contrast(89%) !important;
}

.footerBox {
  display: flex;
  align-items: center;
}

.DEXYNTHIconFooter {
  height: 8vh;
}

.DEXYNTHLogoFooter {
  height: 4vh;
}

@media (max-width: 900px) {
  .Footer-social-link-block .App-social-link {
    margin: 0 1.6rem;
  }
}

@media (max-width: 580px) {
  .Footer-links {
    flex-direction: column;
  }
  .Footer-links > a {
    padding-right: 0;
    margin-bottom: 0.5rem;
  }
  .Footer-social-link-block {
    margin-bottom: 1.4rem;
  }
  .Footer-wrapper {
    padding-top: 2.5rem;
  }
  .home {
    height: 23rem;
  }
  .Footer-links > a:not(:last-child),
  .Footer-links > .a:not(:last-child) {
    padding-right: 0;
  }
  .Footer-social-link-block .App-social-link {
    margin: 0 0.8rem;
  }
}

@media (max-width: 500px) {
  .footerBox {
    flex-direction: column;
    margin-bottom: -40px;
  }

  .footerBox .col {
    padding: 10px;
  }
}
