@import url("../../App/Vars.css");

.TradingTitle {
  display: flex;
  margin-top: 40px;
  width: 100%;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.leaderboard-title {
  color: var(--NARANJA, #fc9344);
  text-align: center;
  font-family: "Lato";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 6.4px;
  margin-bottom: 20px;
}

.orange-title {
  color: var(--NARANJA, #fc9344);
  font-family: "Lato";
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.TradingButtons {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 40px;
}

.RangeButton {
  display: flex;
  width: 41px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  background-color: transparent;
  border: none;
  text-align: center;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.056px;
}

.inactive {
  color: var(--gris-2);
}

.active {
  color: white !important;
}

.TradingCompetition {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.TradingCompetitionHeader {
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.competition-info {
  margin-bottom: 2px;
  font-size: 18px;
  display: flex;
  width: 20%;
  padding: 16px;
  justify-content: center;
  align-items: center;
  background: var(--fondo-2-gris, #19181d);
  text-align: center;
}
