@import url("../../App/Vars.css");

.Tooltip {
  position: relative;
}

.Tooltip-popup {
  font-size: 1.4rem;
  line-height: 1.6rem;
  position: absolute;
  border-radius: 4px;
  padding: 1.05rem;
  letter-spacing: 0.4px;
  min-width: 25rem;
  text-align: left;
  background: linear-gradient(90deg, #0b0b0f 0%, rgba(10, 13, 28, 1) 100%);
  z-index: 9;
}

.Tooltip.nowrap .Tooltip-popup {
  min-width: auto;
  white-space: nowrap;
}

.Tooltip-popup :first-child {
  margin-top: 0;
}

.Tooltip-popup :last-child {
  margin-bottom: 0;
}

.Tooltip-popup.left-bottom {
  left: 0;
  transform: translateY(0.8rem);
  top: 100%;
}

.Tooltip-popup.right-bottom {
  right: 0;
  transform: translateY(0.8rem);
  top: 100%;
}

.Tooltip-popup.right-top {
  right: 0;
  transform: translateY(-0.8rem);
  bottom: 100%;
}
.Tooltip-popup.right {
  left: 3rem;
  transform: translateY(50%);
  bottom: 100%;
}

.Tooltip-popup.left-top {
  left: 0;
  transform: translateY(-0.8rem);
  bottom: 100%;
}

.Tooltip-popup.center-bottom {
  left: 50%;
  transform: translateY(0.8rem) translateX(-50%);
  top: 100%;
}

.Tooltip-popup.center-top {
  left: 50%;
  transform: translateY(-0.8rem) translateX(-50%);
  bottom: 100%;
}

.Tooltip-handle {
  cursor: help;
  position: relative;
  display: inline-flex;
  z-index: 1;
}

.Tooltip-handle.plain:after {
  border-bottom: none;
}

.Tooltip.no-underline .Tooltip-handle::after {
  content: unset;
}

.Tooltip-divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.45);
  margin: 0.5rem 0;
}

.Tooltip-row {
  display: grid;
  margin: 0 0 0.5rem 0;
  grid-template-columns: 1fr auto;
}

.Tooltip-row > span.label {
  margin-right: 0.5rem;
}
.Tooltip-row > .Tooltip-row-value {
  color: var(--gris-1);
  text-align: right;
}

.Tooltip-row-values {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Tooltip-row-values li:not(:first-child) {
  padding: 0.25rem 0 0 0;
  text-align: right;
}

.Tooltip-number {
  color: var(--gris-1);
}

@media (max-width: 700px) {
  .Tooltip-row {
    display: block;
    margin-bottom: 1rem;
  }
  .Tooltip-row > span.label {
    display: block;
  }
  .Tooltip-row-values li {
    text-align: left;
  }
  .Tooltip-popup {
    min-width: 20rem;
  }
  .Tooltip-row > .Tooltip-row-value {
    text-align: left;
  }
}
