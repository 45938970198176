@import url("../../App/Vars.css");

.favs-header {
  width: 100%;
  height: 40px;
  margin: -20px 0px 10px 0px;
  display: flex;
  align-items: center;
  background-color: var(--fondo-1-negro);
  border-bottom: 2px solid var(--fondo-2-gris);
  margin-top: 5px;
  margin-bottom: 4px;
  overflow: hidden;
}

.favs-star {
  background-color: var(--fondo-1-negro);
  margin-left: 15px;
  margin-bottom: 1px;
  line-height: 40px;
  width: 50px;
}

.favs-star img {
  width: 20px;
}

.favs-par {
  font-family: "Lato" !important;
  position: relative;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  line-height: 40px;
  max-width: 240px !important;
  min-width: 240px !important;
  margin-right: 2px;
  background-color: var(--fondo-1-negro);
}

.favs-par::after {
  content: "";
  position: absolute;
  top: 20%;
  margin-left: 230px;
  height: 50%;
  width: 1px;
  background-color: var(--gris-2);
}

.scroll-button {
  position: absolute;
  top: -7px;
  bottom: 0;
  width: 50px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.scroll-buttons {
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
}

.scroll-left-button {
  z-index: 500;
  left: 0;
  background-color: rgba(31, 31, 31, 0.8);
  color: var(--blanco);
  min-width: 50px;
  z-index: 900;
}

.scroll-right-button {
  right: 0;
  background-color: rgba(31, 31, 31, 0.8);
  color: var(--blanco);
  min-width: 50px;
  z-index: 900;
}

.favs-header:hover .scroll-button {
  opacity: 1;
}
